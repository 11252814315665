/* center amplify login */
[data-amplify-authenticator] {
    display: flex;
    justify-content: center;
    height: 100vh;
    padding: 0.75em;

    /*
    --amplify-colors-background-primary: yellow;
    --amplify-colors-background-secondary: black;
    --amplify-colors-brand-primary-10: var(--amplify-colors-teal-100);
    --amplify-colors-brand-primary-80: var(--amplify-colors-teal-40);
    --amplify-colors-brand-primary-90: var(--amplify-colors-teal-20);
    --amplify-colors-brand-primary-100: var(--amplify-colors-teal-10);
    --amplify-colors-font-interactive: var(--amplify-colors-white);
    --amplify-components-tabs-item-active-color: black;
    --amplify-components-tabs-item-focus-color: purple;
    --amplify-components-tabs-item-hover-color: green;
    */
}

[data-amplify-authenticator] [data-state='inactive'] {
    background-color: #f8f8f8;
}

/* make datepicker work nicely w/ material modal */

@import './antd-namespaced.min.css';

/* calendar day view top left foramtting */
.rbc-label.rbc-time-header-gutter {
    padding: 0;
}

/*color the current date */
.rbc-now .rbc-button-link {
    /*background-color: #d5f0ee;*/
    background-color: #7985cb;
    color: white;
    border-radius: 20%;
    padding-left: 0.15em;
    padding-right: 0.15em;
    margin-top: 0.1em;
}

/* background color of today cell is white */
.rbc-day-bg.rbc-today {
    background-color: white;
}

/* calendar text is multi-line*/
.rbc-ellipsis,
.rbc-show-more,
.rbc-row-segment .rbc-event-content,
.rbc-event-label {
    white-space: pre-wrap !important;
}
/* end calendar formatting */

/* overwrite multi date picker set backgroudn white */
.rmdp-container .rmdp-day.rmdp-today span {
    background-color: white;
    color: black;
}
.rmdp-container .rmdp-input-icon,
.multiDateInput .rmdp-input-icon {
    margin-top: 0.5em !important;
    color: #c4c4c4;
    margin-right: 2em;
}

.multiDateInput {
    padding: 0.75em;
    border-radius: 0.25em;
    border-width: 1px;
    border-color: #d9d9d9;
    border-style: solid;

    width: 95%;
    margin-top: 1em;
}
.multiDateInput:focus {
    border-color: #3f51b5 !important;
    outline: -webkit-focus-ring-color auto 1px;
    outline-color: #3f51b5 !important;
    outline-style: auto;
    outline-width: 8px;
    box-shadow: none;
}

.multiDateInput:hover {
    border-color: black;
    border-width: 1px;
}
/* end multi picker */

/* fixes ant date picker in model, only show when 'open' and close when not. ant-slide-up-enter when picker is open */
.ant-picker-dropdown.ant-slide-up-enter,
.ant-picker-dropdown.ant-slide-up-appear {
    z-index: 10000 !important;
}

/*multiple date picker */
/*j
.ant-select-dropdown {
    z-index: 100000 !important;
    max-width: 50em !important;
    position: absolute !important;
}
*/

/*end multiple date picker */
.ant-picker-time-panel-column {
    overflow-x: hidden;
}
.ant-picker-now-btn {
    cursor: pointer;
    color: #1890ff;
}
.ant-picker-today-btn {
    cursor: pointer;
}

.ant-picker {
    border-radius: 4px;
}
.ant-picker:hover {
    border-color: black;
}
.ant-picker-focused,
.ant-picker-focused.ant-picker:hover {
    border-color: #3f51b5;
    outline: -webkit-focus-ring-color auto 1px;
    outline-color: #3f51b5;
    outline-style: auto;
    outline-width: 1px;
    box-shadow: none;
}

.ant-picker:active {
    border-color: #3f51b5;
}

/* end date picker mods */
/* generically - no scroll bars */
body ::-webkit-scrollbar {
    display: none;
    /*scrollbar-width: none; /* Firefox */
    /*width: 0;*/
}

/* add scroll bar to shift table */
.shiftTable .MuiPaper-root ::-webkit-scrollbar-track,
.associateTable .MuiPaper-root ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 30px;
}

.shiftTable .MuiPaper-root ::-webkit-scrollbar,
.associateTable .MuiPaper-root ::-webkit-scrollbar {
    width: 10px;
    display: inherit;
    background-color: #f5f5f5;
}

.shiftTable .MuiPaper-root ::-webkit-scrollbar-thumb,
.associateTable .MuiPaper-root ::-webkit-scrollbar-thumb {
    border-radius: 30px;
    /*
    background-image: -webkit-gradient(
        linear,
        left bottom,
        left top,
        color-stop(0.44, rgb(122, 153, 217)),
        color-stop(0.72, rgb(73, 125, 189)),
        color-stop(0.86, rgb(28, 58, 148))
    );
    */
    background-color: #8895dc;
    /*
    background: rgb(2, 0, 36);
    background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(9, 9, 121, 1) 35%,
        rgba(0, 212, 255, 1) 100%
    );
    */
}

.customScrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 30px;
}

.customScrollbar::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
    display: block;
}

.customScrollbar::-webkit-scrollbar-thumb {
    border-radius: 30px;
    /*
    background-image: -webkit-gradient(
        linear,
        left bottom,
        left top,
        color-stop(0.44, rgb(122, 153, 217)),
        color-stop(0.72, rgb(73, 125, 189)),
        color-stop(0.86, rgb(28, 58, 148))
    );
    */
    background-color: #8895dc;
    /*
    background: rgb(2, 0, 36);
    background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(9, 9, 121, 1) 35%,
        rgba(0, 212, 255, 1) 100%
    );
    */
}

:root {
    --amplify-primary-color: teal;
}
.App {
    /*text-align: center;*/
    min-height: 100vh;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
    min-height: 100vh;
}
/*
amplify-sign-in,
amplify-confirm-sign-up,
amplify-sign-up {
    width: 100vw;
    margin-top: 8em;
    min-height: 100vh;
    display: inline-block;
    text-align: center;
}
*/

.signupTerms {
    top: -0.5em !important;
    position: relative;
}
/*
amplify-authenticator {
    position: relative;
    min-height: 100vh;
}
*/

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 1280px) {
    /* fixes container on small width screens*/
    .MuiContainer-root.MuiContainer-maxWidthLg {
        width: inherit;
    }
}

/* mobile calendar toolbar formatting */
@media only screen and (max-width: 1023px) {
    /*
    .rbc-toolbar .rbc-today-btn {
        display: none;
    }
    */
    .rbc-toolbar .rbc-toolbar-label {
        text-align: right;
        padding: 0;
    }
    .rbc-toolbar .rbc-btn-group {
        margin-top: 0.25em;
        margin-bottom: 1.5em;
    }

    /*white-space: normal;*/

    .rbc-ellipsis,
    .rbc-event-label,
    .rbc-row-segment .rbc-event-content,
    .rbc-show-more {
        white-space: nowrap;
        font-size: 0.7em;
    }

    /* fix weekly calendar table not formatting correctly on mobile */
    .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell,
    .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-date-cell,
    .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-event-cell {
        padding-left: 4px !important;
        padding-right: 4px !important;
    }
}

/* material table row padding */
.associateTable .MuiTableCell-root {
    padding: 4px;
    padding-right: 6px;
    padding-left: 6px;
}
/* end material table */
